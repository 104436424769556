// Define custom bootstrap variables
$primary : #0d1c4b;
$secondary: #DAE0E7;
$success : #42B668;
$border-radius: 0.625rem;
$border-radius-2xl: 27px;
$enable-negative-margins: true;
$light-gray : #C6C9D5;

// fonts 
* {
    font-family: Helvetica, sans-serif;
}

html, body {
    font-synthesis: none
}

@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
}

@import "./keyframe_animations.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

.fs-1, .fs-2, .fs-3{
    font-family: 'Poppins', sans-serif;
}

.fs-1{
    font-size: 25px;
}

.p-standard{
    padding: 13px 42px;
}

.standard-btn{
    padding: 13px 26px;
    min-width: 95px;
    &.big-padding{
        padding: 13px 38px;
    }
}

input, select, option{
    color: $primary !important;
}
html {
	-webkit-text-size-adjust: none;
	touch-action: manipulation;
}

body{
    background-color: $primary;
    color: $primary; 
    touch-action: pan-y;
}

// Login page Responsiveness 
.company-logo {
    img,p{
        max-width: 280px;
    }
}

@media (max-height: 675px) {
    #login-page{
        overflow-x: scroll;
        height: calc(100% - 195px) !important;
    }
    .home-pin{
        height: 8.5vh;
    }

    .ubigrid-logo{
        height: 5.5vh;
    }
}


.overlay{
    position: fixed; 
    display: block; 
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $secondary;
    opacity: 0.75;
    z-index: 1;
    cursor: pointer; 

    .overlay-warning{
        position: fixed;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%,-50%);
        width: 350px;
    }
}
.home-install-summary{
    max-height: 65%;
}

#global-loader, #user-prompt{
    z-index: 2;
    .custom-spinner{
        z-index: 1;
        img{
            animation: spinner-animation 1.2s linear infinite;
        }
        transform: translate(-50%,-50%);
        span{
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-weight: bold;
        }
    }
}

.element-spinner{
    z-index: 1;
    img{
        animation: spinner-animation 1.2s linear infinite;
    }
    transform: translate(-50%,-50%);
    span{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-weight: bold;
    }
}


#user-prompt .overlay-element{
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-name: slidein;
}


.camera-outline{
    border: 2px dashed rgb(0, 0, 0, 0.35);
}


#login-page{
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - 260px);
}

#main-container{
    &.on-login-page{
        margin-top: 240px !important;
    }
    &.header-closed{
        margin-top: 0 !important;
    }
}

#header{
    position: fixed;
    width: 100%;
    padding-bottom: 35px;

}

.ubi-text-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.537);
    padding-bottom: 10px;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    &::placeholder{
        color: rgba(128, 128, 128, 0.537);
    }
}

.ubi-select-input{
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.537);
    padding-bottom: 10px;
    outline: none;
    background-color: transparent;
}
.react-select-container{
    .react-select__control {
        outline: none !important;
        border: none;
        border-bottom: 1px solid rgba(128, 128, 128, 0.537);
        border-radius: 0;
        background-color: transparent;
    }
    .react-select__control--is-focused{
       box-shadow: none;
    }
    .react-select__menu-list{
        background-color: rgba(218,224,231,0.5);
        padding: 0;
    }
    .react-select__option--is-selected{
        font-weight: bold;
        color: $primary;
        background-color: rgba(218,224,231,1);
    }
    .react-select__option--is-focused{
        background-color: rgba(218,224,231,1);
    }
    .react-select__menu{
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 0;
        margin: 0;
    }
    .react-select__option:last-of-type{
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        margin: 0;
    }

    .react-select__value-container{
        padding-left: 5px;
        padding-right: 0px;
    }
    .react-select__single-value{
        color: $primary;
    }
    .react-select__indicators{
        padding-right: 8px;
    }
    .react-select__control--menu-is-open{
        .react-select__indicator {
            transform: rotate(180deg);
        }
    }
    .react-select__indicator {
        padding: 0px;
        opacity: 0.4;
    }
    .react-select__indicator-separator{
        display: none;
    }
}


.qr-container{
    section div{
        position: initial !important;
        padding: none !important;
        overflow: hidden;
        video{
            border-top-left-radius: 35px;
            border-top-right-radius: 35px;
            overflow: hidden;
            object-fit: cover;
        }
        
    }
}

.camera-container{
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    z-index: 110;
    .react-html5-camera-photo {
        position: relative;
        height: 100%;
        width: 100%;

        img{
            display: none !important;
        }
    }
    .camera-show-picture {
        text-align: center;
        img{
            border-radius: 35px;
            width: 100%;
            max-height: calc(100vh - 300px);
        }
    }
    .camera{
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        position:absolute;
        height: 100% !important;
        width: 100%;
        video{
            border-top-left-radius: 35px;
            border-top-right-radius: 35px;
            overflow: hidden;
            object-fit: cover;
            height: 100%;
            width: calc(100%);
        }
    }
    #container-circles{
        z-index: 112;
        #outer-circle{
            bottom: -50px;
        }
        @media (max-height: 630px) {
            bottom: 65px;
        }
    }

}
.paired-unit-container {
    .main-preview{
        div{
            top: 8px;
            right: 12px;  
            img{
                width: 50px;
            }
        }
        img{
            border-radius: 25px;
            max-height: 380px;
            height: 100%;
            object-fit: cover;
        }
    }
    .gallery{
        img{
            max-height: 90px;
            object-fit: cover;
            width: 100%;
            border-radius: 15px;
        }
    }
}


.legacy-gradient-container{
        border-top-left-radius: 27px;
        border-top-right-radius: 27px;
        max-width: 700px;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 100;
        height: 100%;
        width: 100vw;
        clip-path: polygon(0 0%,
                           0% 100%,
                           10% 100%,
                           10% 20%, 
                           90% 20%, 
                           90% 71%, 
                           10% 71%, 
                           10% 100%, 
                           100% 100%, 
                           100% 0%);
    
        @media (max-height: 630px) {
            clip-path: polygon(0 0%,
                            0% 100%,
                            10% 100%,
                            10% 20%, 
                            90% 20%, 
                            90% 79%, 
                            10% 79%, 
                            10% 100%, 
                            100% 100%, 
                            100% 0%);
        }
        backdrop-filter:blur(8px) brightness(80%);
}
.legacy-retacle{
    position: absolute;
    background-color: #fff;
    z-index: 106;
    &.top{
        top: 20%;
    }

    &.bottom{
        bottom: 29%;
        @media (max-height: 630px) {
            bottom: 21%;
        }
    }

    &.left{
        left: 10%;
    }

    &.right{
        right: 10%;
    }

    &.vertical{
        width: 5px;
        height: 70px;
    }

    &.horizontal{
        width: 70px;
        height: 5px;
    }
}

.gradient-container{
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    max-width: 700px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100vw;
    clip-path: polygon(0 0%,
                       0% 100%,
                       3% 100%,
                       3% 20%, 
                       97% 20%, 
                       97% 97%, 
                       3% 97%, 
                       3% 100%, 
                       100% 100%, 
                       100% 0%);
    
    backdrop-filter:blur(8px) brightness(80%);
}
.retacle{
    position: absolute;
    background-color: #fff;
    z-index: 106;
    &.top{
        top: 20%;
    }

    &.bottom{
        bottom: 3%;
    }

    &.left{
        left: 3%;
    }

    &.right{
        right: 3%;
    }

    &.vertical{
        width: 2px;
        height: 20px;
    }

    &.horizontal{
        width: 20px;
        height: 2px;
    }
}


.keyboard-btn{
    bottom: 12%;
    right: 6%;
    @media (max-height: 630px) {
        bottom: 2.5%;
    }
}

.skip-btn{
    bottom: 60px;
    right: 11%;
    @media (max-height: 630px) {
        bottom: 35px;
    }
}




// Make app take 100%
html,
body {
  height: 100%;
  margin: 0;
}

#root{
    display: flex;
    flex-direction: column;
    height: 100%;

    #header{
        flex: 0 1 auto;
    }

    #main-container{
        flex: 1 1 auto;
    }

}

.ubipin-header{
    max-width: 47px !important;
}

.ubi-radio-input{
    display: flex;
    align-items: center;
    gap: 10px;
    
    
    input[type="radio"]{
        background-color: $secondary;
        border: none;
        font-size: 24px;
        margin-bottom: 4px;
    }
    
    input{
        position: relative;
        &:checked{
            &:after{
                content: '';
                position: absolute;
                background-color: $primary;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                top: 6px;
                left: 6px;
            }
        }
    }
}


.ubi-arrow{
    transition: transform 0.3s;
}

td{
    padding-left: 0 !important;
}

.kvaExpand{
    .kva-menu{
        height: 0;
        opacity: 0;
        transform-origin: top;
        transform: scale(1, 0.1);
        transition: 360ms cubic-bezier(0, 1.09, 0, 1.19);
    }
    span{
        white-space: nowrap;
    }
    &.expanded{
        .kva-menu{
            transform: scale(1, 1);
            opacity: 1;
            height: auto;
        }
        span{
            font-weight: bold;
        }
        .ubi-arrow{
            transform: rotate(-180deg);
        }
    }
    span,img{
       pointer-events: none; 
    }

}

.ubi-top-rounded{
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
}

.click-to-slide-up {
    z-index: 500;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    width: 100%;
    background-color: $primary;
    margin-top: -20px;

    filter: drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.355));


    &.display-at-bottom{
        animation: none;
        margin-top: 0;
        position: sticky;
        bottom: 0;
    }

    &.display-at-top{
        margin-top: 0px;
        opacity: 1 !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
    }

    span{
        font-weight: bold;
        font-size: 15px;
    }
    img{
        transform: scale(0.9);
        filter: invert(0.98) saturate(0);
    }


    
}



.status-circle-red{
    margin-left: 20px;
    margin-top: 6.5px;
    display: block;
    width: 23px;
    height: 23px;
    background-color: red;
    border-radius: 50%;
}
.status-circle-green{
    margin-left: 20px;
    margin-top: 6.5px;
    display: block;
    width: 23px;
    height: 23px;
    background-color: $success;
    border-radius: 50%;
}


#map{
    position: relative;
    top: 100%;
    height: 0;
    right: -100%;
    opacity: 0;
    overflow-y: hidden;
    &.full-screen-map,&.quarter-screen-map{
        top: 0;
        opacity: 1;
    }
    &.full-screen-map{
        right: 0%;
        height: 100% !important; 
    }
    &.quarter-screen-map{
        right: 0%;
        height: 35% !important;
        transition: right ease-out 0.4s, opacity linear 0.4s;
    }
    
}

.container{
    padding: 40px 42px !important;
    max-width: 700px;
}

.full-page{
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 81.5px);
    background-color: #fff;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    filter: drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.33));
    top: 80px;

    left: 50%;
    transform: translateX(-50%) !important;

    max-width: 700px;

    &.full-map-page{
        background-color: transparent;
        height: auto;
    }

    &.kva-page, &.check-dtm-page, &.installed-unit, 
    &.logs-page, &.home-page, &.account-page{
        overflow-y: overlay;
    }

    &.map-node-page{
        margin-top: -30px;
        max-height: calc(65% + 30px);
        overflow-y: auto;
        top: auto ;
    }

    &.full-page-slim{
        padding-left: 22px !important;
        padding-right: 22px !important;
    }
}

.table-con-check-container{
    max-height: 65vh;
    &.check-connect-error{
        max-height: 50vh;
    }
}

.map-node-page,.table-con-check-container{
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
}

.prompt-area{
    width: calc(100% - 30px);
    max-width: 500px;
    top: 50%;
    animation: slideFromBottom 500ms;
    &>div{
        padding: 36px;

        @media (max-width: 405px) {
            padding: 28px 24px;
        }
    }

    @media (max-width: 378px) {
        .prompt-img{
            max-height: 225px;
        }
    }
    &.full-width{
        max-width: 100vw !important;
    }
    textarea::placeholder{
        color: rgba(128, 128, 128, 0.537) !important;
    }
}




// remove button border on click
.btn:focus {
    outline: none;
    box-shadow: none;
}
  

.showpass-btn{
    position: relative;
    a, button{
        border: none;
        background-color: transparent;
        position: absolute;
        right: 1%;
    }
}

.login-btn{
    img{
        position: relative;
        right: 30%;
        top: -3px;
    }
}

.btn-disabled{
    background-color: #fff;
    color: #DAE0E7;
    border-color: #DAE0E7;
    pointer-events: none;
    img{
        filter: invert(0.15);
    }
}

.tln-input-page{
    button.disabled{
        background-color: transparent !important;
        color: #DAE0E7 !important;
        border-color: #DAE0E7 !important;
    }
}


.tln-input{
    margin-top: 96px;
    margin-bottom: 125px;

    
    input {
        width : 20px;
        padding: 0px;
        border: none;
        border-radius: 0px;
        border-bottom: 1px solid #99A1B7;
        outline: none;
        &::placeholder{
            color : #CCD0DB;
        }
    }

    input, span{
        text-align: center;
        font-size: 25px;
        font-weight: bold;
    }

    span{
        color: #99A1B7;
    }

    &.active{
        input,span{
            color:black !important;
        }
    }
}

[role=button] {
    cursor: pointer;
}

// fix for transparent.png file not loading in google maps 
.gm-style img[src*="transparent.png"]{
    display: none !important
}

.language-title-fit{
    margin-left: 1rem;
    @media (max-width: 385px) {
        p{
            font-size: 6vw !important;
        }
    }
}

.app-version{
    position: absolute;
    z-index: 2;
    bottom: 0px;
    left: 83%;
    opacity: 0.5;
    font-size: 15px;
}

.fixed-width-btns{
    button{
        width: 40vw;
        min-width: 100px;
        max-width: 135px;
    }
}

.ubi-table{
    th{
        vertical-align: middle;
    }
    td{
       white-space: nowrap;
       vertical-align: middle;
    }
    tbody tr:hover{
        background-color: $secondary;
        cursor: pointer;
    }
}

.carousel.slide {
    position: relative;
}

.carousel-indicators{
    div{
        height: 5px;
        width: 45px;
        cursor: pointer;
        &:hover{
            background-color: #0d1c4b !important;
        }
        &.active{
            background-color: #0d1c4b !important
        }
    }
}
  
.carousel-caption {
    position: static;
}

// **UBICELL STYLES
.ubi-prompt{
    .overlay{
        top: -80px;
        position: fixed;
        left: 0;
        width: 100%;
        height: calc(80px + 100%);
        background-color: red;
        z-index: 3;
        background-color: $primary;
        opacity: 0.95;
    }
    .prompt{
        padding: 40px 35px;
        position: absolute;
        z-index: 4;
        background-color: white;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        border-radius: 32px;
        min-width: 350px;
        max-width: 390px;
        //white-space: pre-line;
    }
}


.ubi-text-area{
    border-radius: 16px;
    width: 100%;
    padding: 16px;
    border: 1px solid $secondary;
    outline: none;
}

.ubi-text-area-counter{
    color: $secondary;
    padding-left: 16px;
}

.ubi-button{
    background-color: $primary;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    border-style: hidden;

    width: 104px;
    height: 42px;

    &.long-btn{
        width: 200px !important;
    }

    &.secondary-btn{
        background-color: $secondary;
        color: $primary
    }

    &.regular-btn{
        width: 147px;
        height: 64px;
    }

    @media (max-width: 380px) {
        width: 127px;
       
    }



    &.disabled-btn{
        background-color: white;
        border-color: $light-gray;
        color: $light-gray;
        pointer-events: none;
        border-style: solid;

        &.with-icon{
            img{
                filter: invert(0.5)
            }
        }
    }

    &.top-margin{
        margin-top: 32px;
    }
    &.bottom-margin{
        margin-bottom: 32px;
    }

    &.with-icon{
        img{
            vertical-align: top;
            margin-right: 15px;
        }
    }
}

.page-card-title{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
}

.mic-circle-container{

    .mic-btn{
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -khtml-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        cursor: pointer;
        position: absolute;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: 1px solid #DAE0E7;
        background-color: transparent;
        img{
            -webkit-touch-callout: none;
            pointer-events: none;
        }
    }

    .CircularProgressbar {
        width: 130px;
        height: 130px;
        z-index: 4;
        transform: scale(1.12);
        pointer-events: none;
    }

    @media (max-height: 600px) {
        .mic-btn, .CircularProgressbar{
            width: 100px;
            height: 100px;
        }
    }
    .recording-bubble{
        background-color: red;
        border-radius: 50%;
        width: 13px;
        height: 13px;
    }


    .mic-circle{
        position: relative;
        &::after{
            content: "";
            position: absolute;
            z-index: 3;
            width: 146px;
            height: 146px;
            background-color: red;
            border-radius: 50%;
            border: 3px solid blue;
            top: -2.5px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.ubi-blank-btn{
    background-color: transparent;
    border: none;
    img{
        -webkit-touch-callout: none;
        pointer-events: none;
    }
}
//**

.image-viewer-container{
   &.inside-full-page #ReactSimpleImageViewer{
    z-index: 3;
    top: -80px !important;
    height: calc(100% + 80px) !important;
   } 
   .delete-btn{
    position: absolute;
    z-index: 4;
    transform: translateX(50%);
    right: 50%;
    top: 85%;
   }
}

